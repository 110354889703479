import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Step from "@material-ui/core/Step"
import StepContent from "@material-ui/core/StepContent"
import StepLabel from "@material-ui/core/StepLabel"
import Stepper from "@material-ui/core/Stepper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}))

function getSteps() {
  return [
    "Post a project",
    "Students sign up",
    "Choose a student to work with",
    "Communicate remotely",
    "Project completion",
    "Grant volunteer hours",
    "Enjoy the benefits of RemoteTA!",
  ]
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `Click on 'Post a Project'. Then, briefly describe the project details and click 'Post'.`
    case 1:
      return "Interested students can sign up your posted projects from their portal. You will be notified via email notifications when these students sign up."
    case 2:
      return "You can view all students who have signed up for your project by navigating to the 'Posted Projects' tab, viewing the details of your project, and clicking the 'Choose Student' button. Then, you can choose a student to work with based on their skills and experiences."
    case 3:
      return "Your project is now visible under the 'Active Projects' tab. All communications will now take place with the student over email. You can retrieve their email from the 'Contact Student' button found in the details of your project card (now located in Active Projects)."
    case 4:
      return "After communicating with the student over email, the student will complete the project by the deadline and email it to you. You can also request multiple drafts of the project from the student at different dates, allowing you to provide feedback and opportunities for improvement. "
    case 5:
      return "Upon emailing their final version of the project to you, the student will request volunteer hours for their work (you will be notified via email when they do so). This request will appear in the Volunteer Hour Requests tab. You can view their work log, and grant them volunteer hours."
    case 6:
      return "By using RemoteTA you've saved yourself time and energy while also giving a high school student the opportunity to develop several skills. If you need more assistance, visit remoteta.org/Help."

    default:
      return "Unknown step"
  }
}

function TeacherStepper() {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  )
}

export default TeacherStepper
