import { navigate } from "gatsby"
import React from "react"
import { Tab, Tabs } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import ActiveTab from "../components/dashboard/ActiveTab"
import { auth } from "../firebase"
import Layout from "../components/utils/layout"
import Loader from "../components/utils/Loader"
import PendingTab from "../components/dashboard/PendingTab"
import SEO from "../components/utils/seo"
import SubmittedTab from "../components/dashboard/ReviewTab"
import TeacherStepper from "../components/dashboard/TeacherStepper"
import TitleLine from "../components/utils/TitleLine"
import getUserData from "../scripts/getUserData"
import "../styles/createStudentAccountPage.css"
import logout from "../scripts/logout"
import dots from "../images/bgs/dots.svg"

class NewTeacherDashboard extends React.Component {
  navigateToPost() {
    navigate("/PostTask/")
  }

  navigateToHome() {
    navigate("/")
  }

  navigateTo404() {
    navigate("/404/")
  }

  constructor(props) {
    super(props)
    this.state = {
      run: false,
      authenticated: null,
      authExecuted: false,
      numPosted: 0,
      numActive: 0,
      numReview: 0,
      refreshTabs: [false, false, false],
      initialized: false,
      joyride: false,
      currentKey: "t1",
      stepperWidth: null,
      steps: [
        {
          target: "body",
          content:
            "This tutorial will walk you through everything on your screen right now. Viewing the 'What's the Process?' page is another way to get help/guidance",
          placement: "center",
        },
        {
          target: ".firstTabOne",
          content:
            "These are your posted projects. Students can view these projects and sign up for them. You receive and email notification every time a student signs up.",
        },
        {
          target: ".firstTabTwo",
          content:
            "Clicking this button will bring you to a page where you can detail your project and post it.",
        },
        {
          target: ".firstTabFour",
          content:
            "These are your posted projects. Upon hovering over the card and clicking Learn More you can learn the details of the project you posted. If a student has signed up for the project, you will see the Choose Student button",
        },
      ],
      screenWidth: null,
    }
    this.setRefresh = this.setRefresh.bind(this)
    this.refresh = this.refresh.bind(this)
    this.runJoyride = this.runJoyride.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.stopJoyride = this.stopJoyride.bind(this)
    this.joyrideAlert = this.joyrideAlert.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth })
    if (window.innerWidth >= 1024) {
      this.setState({ stepperWidth: 774 })
    } else if (window.innerWidth > 578) {
      this.setState({ stepperWidth: 720 })
    }
    this.refresh = this.refresh.bind(this)
    this.runJoyride = this.runJoyride.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.stopJoyride = this.stopJoyride.bind(this)
    this.joyrideAlert = this.joyrideAlert.bind(this)
    this.init = this.init.bind(this)
    this.setRefresh = this.setRefresh.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions)
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
        // this.navigateToHome()
      } else {
        if (!user.emailVerified) {
          logout().then(() => {
            this.navigateToHome()
          })
        } else {
          this.setState({ authenticated: true })
        }
      }
    })
  }

  setRefresh(newRefresh) {
    this.setState({ refreshTabs: newRefresh })
  }

  init() {
    if (this.state.authenticated) {
      this.setState({ initialized: true })
      let user_id = auth.currentUser.uid
      getUserData(user_id)
        .then(data => {
          if (data["userType"] !== "teacher") {
            navigate("/")
            return
          }
          let newNumAccepted = data["activeProjects"].length
          let newNumPosted = data["pendingProjects"].length
          let newNumReview = Object.keys(data["reviewProjects"]).length
          this.setState({
            numPosted: newNumPosted,
            numActive: newNumAccepted,
            numReview: newNumReview,
          })
        })
        .catch(error => {
          console.log(error)
          //TODO Error handle
        })
    }
  }

  runJoyride() {
    this.setState({ joyride: true })
  }

  stopJoyride() {
    this.setState({ joyride: false })
    this.setState({ steps: this.state.steps })
  }

  handleSelect(key) {
    if (key === "t1") {
      this.setState({
        steps: [
          {
            target: "body",
            content:
              "This tutorial will walk you through everything on your screen right now. Viewing the 'What's the Process?' page is another way to get help/guidance",
            placement: "center",
          },
          {
            target: ".firstTabOne",
            content:
              "These are your posted projects. Students can view these projects and sign up for them. You receive and email notification every time a student signs up.",
          },
          {
            target: ".firstTabTwo",
            content:
              "Clicking this button will bring you to a page where you can detail your project and post it.",
          },
          {
            target: ".firstTabFour",
            content:
              "These are your posted projects. Upon hovering over the card and clicking Learn More you can learn the details of the project you posted. If a student has signed up for the project, you will see the Choose Student button",
          },
        ],
      })
    } else if (key === "t2") {
      this.setState({
        steps: [
          {
            target: "body",
            content:
              "This tutorial will walk you through everything on your screen right now. Viewing the 'What's the Process?' page is another way to get help/guidance",
            placement: "center",
          },
          {
            target: ".secondTabOne",
            content:
              "These are your accepted projects. Accepted projects are the projects you have chosen a student for. For active projects, you communicate with the student over email to ensure they have everything they need to complete the project",
          },
          {
            target: ".secondTabTwo",
            content:
              "Clicking this button will bring you to a page where you can detail your project and post it.",
          },
          {
            target: ".secondTabFour",
            content:
              "This is one of your active projects. Hover over the project card and click Learn More to view details about your project and contact the student",
          },
        ],
      })
    } else {
      this.setState({
        steps: [
          {
            target: "body",
            content:
              "This tutorial will walk you through everything on your screen right now. Viewing the 'What's the Process?' page is another way to get help/guidance",
            placement: "center",
          },
          {
            target: ".thirdTabOne",
            content:
              "These are your volunteer hour requests. These requests are where you will review your student's volunteer hour logs and grant them volunteer hours for their work.",
          },
          {
            target: ".thirdTabTwo",
            content:
              "Clicking this button will bring you to a page where you can detail your project and post it.",
          },
          {
            target: ".thirdTabThree",
            content:
              "This is a student's volunteer hours request. You can view their work log and grant them volunteer hours by clicking the button.",
          },
        ],
      })
    }
    this.setState({ joyride: false })
    this.setState({ currentKey: key })
  }

  joyrideAlert(steps) {
    this.setState({ steps: steps, joyride: false })
  }

  refresh() {
    this.setState({ initialized: false })
  }

  render() {
    if (!this.state.initialized) {
      this.init()
    }
    if (this.state.initialized) {
      let absoluteButtonControls = <></>
      let postFromTabs = <></>
      if (this.state.screenWidth >= 1440) {
        absoluteButtonControls = (
          <div className="selector">
            <Button
              className="selector firstTabTwo secondTabTwo thirdTabTwo"
              variant="outline-primary"
              onClick={this.navigateToPost}
              style={{
                position: "absolute",
                top: "110px",
                right: "90px",
              }}
            >
              Post A Project
            </Button>
          </div>
        )
      } else {
        postFromTabs = (
          <Tab eventKey="t0.5" title="Post a Project">
            <Container>
              <Row
                style={{
                  marginTop: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <h3 class="noneText" style={{ color: "#000" }}>
                  Post a project to receive the help of our dedicated student
                  volunteers.
                </h3>
              </Row>
              <Row
                style={{ marginTop: "-10px" }}
                className="justify-content-center"
              >
                <Button
                  variant="primary"
                  className=" browse-opportunities"
                  onClick={this.navigateToPost}
                >
                  Post A Project
                </Button>
              </Row>
            </Container>
          </Tab>
        )
      }
      let postedTitle = (
        <div className="dashboardTabTitles">
          <span>Posted Projects&nbsp;</span>
          <span className="redText">({this.state.numPosted})</span>
        </div>
      )
      let activeTitle = (
        <div className="dashboardTabTitles">
          <span>Active Projects&nbsp;</span>
          <span className="redText">({this.state.numActive})</span>
        </div>
      )
      let submittedTitle = (
        <div className="dashboardTabTitles">
          <span>Student Volunteer Hour Requests&nbsp;</span>
          <span className="redText">({this.state.numReview})</span>
        </div>
      )
      let full = false
      if (this.state.currentKey === "t1") {
        full = this.state.numPosted <= 2
      } else if (this.state.currentKey === "t2") {
        full = this.state.numActive <= 2
      } else if (this.state.currentKey === "t0") {
        full = true
      } else if (this.state.currentKey === "t0.5") {
        full = true
      } else {
        full = this.state.numReview <= 2
      }
      return (
        <>
          <div class="createStudentAccountPage">
            <Layout image={dots} full={full}>
              <SEO title="Teacher Portal" />
              <Container className="tbMargins">
                <div className="selector">
                  <TitleLine title="Teacher Portal" />
                </div>
                {absoluteButtonControls}
              </Container>
              <Container className="tabalign">
                <Tabs
                  defaultActiveKey="t2"
                  activeKey={this.state.currentKey}
                  id="tablayout"
                  onSelect={this.handleSelect}
                  className="teacherTabProperties"
                >
                  <Tab eventKey="t0" title="What's the Process?">
                    <Container fluid={true} className="teacherStepperAlign">
                      <TeacherStepper />
                    </Container>
                  </Tab>
                  {postFromTabs}
                  <Tab eventKey="t1" title={postedTitle}>
                    <PendingTab
                      userType="teacher"
                      refresh={this.refresh}
                      navigateToPost={this.navigateToPost}
                      joyrideAlert={this.joyrideAlert}
                      refreshTabs={this.state.refreshTabs}
                      setRefresh={this.setRefresh}
                    ></PendingTab>
                  </Tab>
                  <Tab eventKey="t2" title={activeTitle}>
                    <ActiveTab
                      userType="teacher"
                      refresh={this.refresh}
                      joyrideAlert={this.joyrideAlert}
                      refreshTabs={this.state.refreshTabs}
                      setRefresh={this.setRefresh}
                    ></ActiveTab>
                  </Tab>
                  <Tab eventKey="t3" title={submittedTitle}>
                    <SubmittedTab
                      userType="teacher"
                      refresh={this.refresh}
                      refreshTabs={this.state.refreshTabs}
                      setRefresh={this.setRefresh}
                    ></SubmittedTab>
                  </Tab>
                </Tabs>
              </Container>
            </Layout>
          </div>
        </>
      )
    } else {
      return (
        <Layout full>
          <Loader />
        </Layout>
      )
    }
  }
}

export default NewTeacherDashboard
